<template>
  <div class="generate-txt-modal">
    <b-modal
      v-model="showGenerateTxtModal"
      @hide="hideGenerateTxtModal"
      class="gmv-upload-modal-container"
      id="uploadModal"
      scrollable
      title="Generar su archivo txt"
    >
      <div
        v-loading="Loading"
        element-loading-text="Procesando..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(245 , 229, 229, 0.8)"
        style="width: 100%"
      >
        <b-form
          id="form"
          name="generate-txt-modal__form"
          v-on:submit.prevent=""
        >
          <b-card-text>
            <div class="inputs">
              <label class="inputs__title">Archivos</label>
              <div class="inputs__grid">
                <b-form-input
                  class="gmv-process-modal-form__inputs"
                  size="sm"
                  list="input-list"
                  placeholder="Ruta Final*"
                  v-model="processGenerateTxt.ruta_final"
                  id="input-with-list"
                ></b-form-input>
                <b-form-datalist
                  id="input-list"
                  :options ="filesFinalRoute"
                ></b-form-datalist>
                <b-input
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  name="ruta rechazos"
                  autocomplete="off"
                  size="sm"
                  type="text"
                  class="gmv-process-modal-form__inputs"
                  v-model="processGenerateTxt.ruta_desmontes"
                  placeholder="Ruta desmontes*"
                ></b-input>
                <b-input
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  name="ruta status"
                  autocomplete="off"
                  size="sm"
                  type="text"
                  class="gmv-process-modal-form__inputs"
                  v-model="processGenerateTxt.ruta_status"
                  placeholder="Ruta status*"
                ></b-input>
              </div>
            </div>
            <hr />
            <div class="gmv-process-modal-button"></div
          ></b-card-text>
        </b-form>

        <FileUpload
          :auto="false"
          accept=".xlsx,.prt,.xlsb,.txt,.csv"
          :maxFileSize="100000000"
          @select="filesChange"
          :fileLimit="1"
          chooseLabel="haz clic para cargar"
          :showCancelButton="false"
          :showUploadButton="false"
        >
          <template #empty>
            <i class="el-icon-upload fa-6x" style="color: #b3282d"></i>
            <div class="el-upload__text">Suelta tu archivo aquí</div>
          </template>
        </FileUpload>

        <div slot="tip" class="el-upload__tip">
          Solo archivos XLSB, XLSX, TXT, CSV con un tamaño menor a 100Mb
        </div>
        <hr />
        <div class="button-upload-files">
          <el-radio-group v-model="parametersGenerateBancolombia">
            <el-radio
              v-for="(
                queryGenerateBancolombia, index
              ) in queriesGenerateBancolombia"
              :label="queryGenerateBancolombia"
              :key="index"
              >{{ queryGenerateBancolombia.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div
          class="process-button__content"
          v-if="showGenerateTxtProcessButton"
        >
          <Button
            class="upload-button"
            type="submit"
            @click="GenerateTxtProcess"
            >Generar txt
          </Button>
        </div>
      </div>
      <template #modal-footer>
        <div class="gmv-upload-modal-button"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import { Loading } from "element-ui";
import queryGenerateVariablesBancolombia from "@/utils/generateVariablesBancolombia";

export default {
  inject: ["reload"],
  name: "GenerateTxtModal",
  props: {
    openGenerateTxtModal: Boolean,
    bankName: Array,
  },
  components: {
    FileUpload,
    Loading,
    Button,
  },
  data() {
    return {
      showGenerateTxtModal: false,
      processGenerateTxt: {
        ruta_final: "",
        ruta_desmontes: "",
        ruta_status: "",
      },
      Loading: false,
      showGenerateTxtProcessButton: false,
      parametersBanks: null,
      parametersGenerateBancolombia: queryGenerateVariablesBancolombia[0],
      queriesGenerateBancolombia: queryGenerateVariablesBancolombia,
      filesFinalRoute: [],
    };
  },
  watch: {
    openGenerateTxtModal(val) {
      if(val){
        this.getFiles();
      }
      this.openGenerateTxtModal == true
        ? (this.showGenerateTxtModal = true)
        : (this.openGenerateTxtModal = false);
    },
    processGenerateTxt: {
      deep: true,
      handler() {
        this.formGenerateTxtValidation();
      },
    },
  },
  methods: {
    async getFiles() {
      let paramsModal = {
        type: 'final',
        bucket_type: "output",
        bank: this.bankName,
        skip: 0,
        limit: 50
      };
      let response = await this.$store.dispatch("files/getFiles", paramsModal);
        this.filesFinalRoute = response.data
        .sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        })
        .map((el) => el.name);
    },
    hideGenerateTxtModal() {
      this.showGenerateTxtProcessButton = false;
      for (let i in this.processGenerateTxt) {
        this.processGenerateTxt[i] = "";
      }
      this.$emit("closeGenerateTxtModal");
    },
    async filesChange(generateTxtfile) {
      let data = new FormData();
      let response;
      data.append("file", generateTxtfile.files[0]);
      response = await this.$store.dispatch("upload/uploadFilesBancolombia", {
        body: data,
        selectedOption: this.parametersGenerateBancolombia.value,
      });
      if (this.parametersGenerateBancolombia.value == "desmontes") {
        this.processGenerateTxt.ruta_desmontes = response.data.directory;
      } else if (this.parametersGenerateBancolombia.value == "status") {
        this.processGenerateTxt.ruta_status = response.data.directory;
      }
    },
    formGenerateTxtValidation() {
      let count = 0;
      for (let i in this.processGenerateTxt) {
        if (this.processGenerateTxt[i] != "") {
          count += 1;
        }
      }
      count == 3 ? (this.showGenerateTxtProcessButton = true) : "";
    },
    async GenerateTxtProcess() {
      this.Loading = true;
      let body = {};
      body.ruta_final = this.processGenerateTxt.ruta_final;
      body.ruta_desmontes = this.processGenerateTxt.ruta_desmontes;
      body.ruta_status = this.processGenerateTxt.ruta_status;
      await this.$store.dispatch("processBase/postGenerateTxt", body);
      this.Loading = false;
      this.reload({ name: "ReportedFiles" });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  &__title {
    color: #717274;
    margin-bottom: 0;
  }
  &__grid {
    display: flex;
    margin: 10px;
  }
}
.button-upload-files {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.gmv-process-modal-form {
  &__inputs {
    margin: 10px;
  }
}
.process-button__content {
  display: flex;
  justify-content: right;
}
@include upload-button;
.gmv-process-modal-button {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}
::v-deep .p-fileupload-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
::v-deep .modal.show .modal-dialog {
  transform: none;
  max-width: 45%;
}
::v-deep .p-fileupload .p-fileupload-content {
  padding: 4rem 4rem;
  border: 2px dashed #dee2e6;
  color: #495057;
  border-radius: 10px;
  &:hover {
    border: 2px dashed #b3282d;
  }
}
::v-deep .p-fileupload-choose:not(.p-disabled) {
  background: transparent;
  color: #b3282d;
  border-color: transparent;
  &:hover {
    background: transparent;
    color: #fd040c;
    border-color: transparent;
  }
}
::v-deep .p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  border: none;
  padding: 0;
}
::v-deep .modal-footer {
  display: none;
}
::v-deep .el-upload__tip {
  font-size: 14px;
  color: #606266;
  margin-top: 7px;
}
</style>
