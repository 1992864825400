<template>
  <div class="base-bex-modal">
    <b-modal
      v-model="showModal"
      @hide="hideModal"
      class="gmv-upload-modal-container"
      id="uploadModal"
      scrollable
      title="Suba su archivo Base Bex"
    >
      <div
        v-loading="Loading"
        element-loading-text="Procesando..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(245 , 229, 229, 0.8)"
        style="width: 100%"
      >
        <b-form id="form" name="base-bex-modal__form" v-on:submit.prevent="">
          <b-card-text>
            <div class="inputs">
              <label class="inputs__title">Archivos</label>
              <div class="inputs__grid">
                <b-form-input
                  class="gmv-process-modal-form__inputs"
                  size="sm"
                  list="input-list"
                  placeholder="Ruta Final*"
                  v-model="processBexBase.ruta_final"
                  id="input-with-list"
                ></b-form-input>
                <b-form-datalist
                  id="input-list"
                  :options="filesFinalRoute"
                ></b-form-datalist>
                <b-input
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  name="ruta rechazos"
                  autocomplete="off"
                  size="sm"
                  type="text"
                  class="gmv-process-modal-form__inputs"
                  v-model="processBexBase.ruta_rechazos"
                  placeholder="Ruta rechazos*"
                ></b-input>
              </div>
            </div>
            <hr />
            <div class="inputs">
              <label class="inputs__title">Cosechas</label>
              <div class="inputs__grid">
                <b-input
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  name="fecha cosecha"
                  autocomplete="off"
                  size="sm"
                  type="text"
                  class="gmv-process-modal-form__inputs"
                  v-model="processBexBase.fecha_cosecha"
                  placeholder="Código Año/Mes*"
                ></b-input>
                <b-input
                  required
                  title="Este campo es obligatorio, número admitidos 1 o 2"
                  aria-label="required"
                  name="cosecha"
                  autocomplete="off"
                  size="sm"
                  type="number"
                  max="2"
                  min="1"
                  class="gmv-process-modal-form__inputs"
                  v-model="processBexBase.cosecha"
                  placeholder="Cosecha*"
                ></b-input>
              </div>
            </div>
            <hr />
            <div class="inputs">
              <label class="inputs__title">Duplicados</label>
              <div class="inputs__grid">
                <b-form-datepicker
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  name="fecha_inicial_no_interesados"
                  size="sm"
                  locale="es"
                  class="gmv-process-modal-form__date"
                  v-model="processBexBase.date_init_duplicates"
                  placeholder="Fecha inicial*"
                ></b-form-datepicker>
                <b-form-datepicker
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  name="fecha_final_no_interesados"
                  size="sm"
                  locale="es"
                  class="gmv-process-modal-form__date"
                  v-model="processBexBase.date_finish_duplicates"
                  placeholder="Fecha final*"
                ></b-form-datepicker>
              </div>
            </div>
            <hr />
            <div class="gmv-process-modal-button"></div
          ></b-card-text>
        </b-form>
        <div class="inputs">
          <label class="inputs__title">Ruta rechazos simulación</label>
          <FileUpload
            :auto="false"
            accept=".xlsx,.prt,.xlsb,.txt,.csv"
            :maxFileSize="100000000"
            @select="filesChange"
            :fileLimit="1"
            chooseLabel="haz clic para cargar"
            :showCancelButton="false"
            :showUploadButton="false"
          >
            <template #empty>
              <i class="el-icon-upload fa-6x" style="color: #b3282d"></i>
              <div class="el-upload__text">Suelta tu archivo aquí</div>
            </template>
          </FileUpload>
        </div>
        <div slot="tip" class="el-upload__tip">
          Solo archivos XLSB, XLSX, TXT, CSV con un tamaño menor a 100Mb
        </div>
        <div class="process-button__content" v-if="showProcessButton">
          <Button class="upload-button" type="submit" @click="BaseBexProcess"
            >Procesar
          </Button>
        </div>
      </div>
      <template #modal-footer>
        <div class="gmv-upload-modal-button"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import { Loading } from "element-ui";

export default {
  inject: ["reload"],
  name: "BaseBexModal",
  props: {
    openModal: Boolean,
    bankName: Array,
  },
  components: {
    FileUpload,
    Loading,
  },
  data() {
    return {
      showModal: false,
      processBexBase: {
        ruta_final: "",
        ruta_rechazos: "",
        fecha_cosecha: "",
        cosecha: "",
        date_init_duplicates: "",
        date_finish_duplicates: "",
      },
      Loading: false,
      showProcessButton: false,
      filesFinalRoute: [],
    };
  },
  watch: {
    openModal(val) {
      if(val){
        this.getFiles();
      }
      this.openModal == true
        ? (this.showModal = true)
        : (this.openModal = false);
    },
    processBexBase: {
      deep: true,
      handler() {
        this.formValidation();
      },
    },
  },
  methods: {
    async getFiles() {
      let paramsModal = {
        type: 'final',
        bucket_type: "output",
        bank: this.bankName,
        skip: 0,
        limit: 20,
      };
      let response = await this.$store.dispatch("files/getFiles", paramsModal);
        this.filesFinalRoute = response.data
        .sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        })
        .map((el) => el.name);

    },

    hideModal() {
      this.showProcessButton = false;
      for (let i in this.processBexBase) {
        this.processBexBase[i] = "";
      }
      this.$emit("closeModal");
    },
    async filesChange(baseBexfile) {
      let data = new FormData();
      let response;
      data.append("file", baseBexfile.files[0]);
      response = await this.$store.dispatch("upload/uploadFilesBancolombia", {
        body: data,
        selectedOption: "rechazos_simulacion",
      });
      this.processBexBase.ruta_rechazos = response.data.directory;
    },
    formValidation() {
      let count = 0;
      for (let i in this.processBexBase) {
        if (this.processBexBase[i] != "") {
          count += 1;
        }
      }
      count == 6 ? (this.showProcessButton = true) : "";
    },
    async BaseBexProcess() {
      this.Loading = true;
      let body = {};
      body.ruta_final = this.processBexBase.ruta_final;
      body.fecha_cosecha = this.processBexBase.fecha_cosecha;
      body.cosecha = this.processBexBase.cosecha;
      body.ruta_rechazos = this.processBexBase.ruta_rechazos;
      body.date_init_duplicates = this.processBexBase.date_init_duplicates;
      body.date_finish_duplicates = this.processBexBase.date_finish_duplicates;
      await this.$store.dispatch("processBase/postBexBase", body);
      this.Loading = false;
      this.reload({ name: "ReportedFiles" });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  &__title {
    color: #717274;
    margin-bottom: 0;
  }
  &__grid {
    display: flex;
    margin: 10px;
  }
}
.gmv-process-modal-form {
  &__inputs {
    margin: 10px;
  }
}
.process-button__content {
  display: flex;
  justify-content: right;
}
@include upload-button;
.gmv-process-modal-button {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}
::v-deep .p-fileupload-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
::v-deep .modal.show .modal-dialog {
  transform: none;
  max-width: 45%;
}
::v-deep .p-fileupload .p-fileupload-content {
  padding: 4rem 4rem;
  border: 2px dashed #dee2e6;
  color: #495057;
  border-radius: 10px;
  &:hover {
    border: 2px dashed #b3282d;
  }
}
::v-deep .p-fileupload-choose:not(.p-disabled) {
  background: transparent;
  color: #b3282d;
  border-color: transparent;
  &:hover {
    background: transparent;
    color: #fd040c;
    border-color: transparent;
  }
}
::v-deep .p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  border: none;
  padding: 0;
}
::v-deep .modal-footer {
  display: none;
}
::v-deep .el-upload__tip {
  font-size: 14px;
  color: #606266;
  margin-top: 7px;
}
</style>
